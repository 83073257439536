<template>
  <div class="user-info-page">
    <div class="user-info" v-loading="loading">
      <div class="Changes">
        <span class="portrait">
         <el-upload
            class="avatar-uploader"
            action="/api/upload/image"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
            :data="{ type: 'avatar' }"
          >
          <img :src="user.headImageUrl" alt="">
          </el-upload>
          </span>
        <div v-if="cursores" class="texts"><span>{{user.wechatNickname}}</span></div>
        <!-- <span v-if="cursores" @click="cursores=false" class="cursor-style teaches">修改昵称</span> -->
        <span v-if="!cursores" style="width:160px;margin-right: 15px;"><el-input v-model="nickname"></el-input></span>
        <span v-if="!cursores" class="alteration">
            <span @click="cursores=true" >取消</span>
            <span @click="nicknamoes" class="cursor-style" style="margin-left: 25px;">确定</span>
        </span>
      </div>
      <div class="message-list">
        <span>账户ID<span class="op0" style="width:0;">:</span></span>
        <span>{{ user.userId}}</span>
        <span v-if="user.memberExpireDate" class="cursor-style"  style="color:red;margin-left: -8px;"
          >（{{ user.memberExpireDate }}会员到期）</span>
      </div>
      <!-- <div class="message-list">
        <span>{{ user.wechatNickname ? '微信号' : '邮箱号' }}</span>
        <span>{{ user.wechatNickname || user.loginName }}</span>
      </div> -->
      <div class="message-list">
        <span>手机号</span>
        <span>{{ user.mobile ? user.mobile : '未绑定手机号' }}</span>
        <span class="cursor-style" @click="handerEdit(0)" v-if="user.mobile"
          >修改手机号</span
        >
        <span class="cursor-style" @click="handerEdit(1)" v-else
          >绑定手机号</span
        >
      </div>
      <div class="message-list">
        <span>余额</span>
        <span class="red w-auto">
          {{ user.coinNum + user.giftCoinNum + '金币' }}
          <span class="red w-auto" v-if="user.giftCoinNum">(含赠送{{ user.giftCoinNum }}金币)</span>
        </span>
        <!-- <span style="cursor: pointer" @click="handerWithdraw">提现</span> -->
      </div>
      <!--
      <div class="message-list">
        <span>免费查询</span>
        <span class="searchFreeNum">{{ user.searchFreeNum + '次' }}</span>
        <span class="cursor-style" @click="handerRecord">记录</span>
        <span class="cursor-style" @click="openActivity"
          >如何获取免费查询次数</span
        >
      </div>
      -->
    </div>
    <el-dialog
      :title="titleDV"
      :visible.sync="editPhoneDV"
      width="460px"
      :before-close="closeEditPhoneDV"
      class="edit-phone-DV"
      :close-on-click-modal="false"
    >
      <template v-if="user.mobile">
        <el-steps
          :active="active"
          finish-status="success"
          align-center
          v-if="active !== 2"
        >
          <el-step title="验证身份"></el-step>
          <el-step title="修改手机号"></el-step>
          <el-step title="完成"></el-step>
        </el-steps>
        <template v-if="active == 0">
          <div class="phone">{{ getMobile }}</div>
          <el-form
            ref="form"
            :model="loginFormData"
            label-width="22px"
            class="login-form"
          >
            <el-form-item>
              <el-input
                v-model="loginFormData.captcha"
                placeholder="图形验证码"
              ></el-input>
              <div class="right-content">
                <img :src="imgCode" @click="tapCutImg" />
              </div>
            </el-form-item>
            <el-form-item>
              <el-input
                v-model="loginFormData.validateCode"
                placeholder="验证码"
              ></el-input>
              <div class="right-content">
                <span @click="getCode(0)" v-if="!isCount">获取验证码</span>
                <span class="down" v-else>{{ count }}秒后重发</span>
              </div>
            </el-form-item>
          </el-form>
        </template>
        <template v-else-if="active == 1">
          <el-form
            ref="form"
            :model="editFormData"
            label-width="22px"
            class="login-form tow-step"
          >
            <el-form-item>
              <el-input
                v-model="editFormData.newMobile"
                placeholder="手机号码"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-input
                v-model="editFormData.captcha"
                placeholder="图形验证码"
              ></el-input>
              <div class="right-content">
                <img :src="imgCode" @click="tapCutImg" />
              </div>
            </el-form-item>
            <el-form-item>
              <el-input
                v-model="editFormData.validateCode"
                placeholder="验证码"
              ></el-input>
              <div class="right-content">
                <span @click="getCode(1)" v-if="!isCount">获取验证码</span>
                <span class="down" v-else>{{ count }}秒后重发</span>
              </div>
            </el-form-item>
          </el-form>
        </template>
        <template v-else>
          <div class="three-step">
            <i class="el-icon-success"></i>
            <div class="edit-success">修改成功</div>
            <div class="my-phone">您的手机号：{{ this.user.mobile }}</div>
          </div>
        </template>
      </template>
      <template v-else>
        <div class="tip">
          为了您的资金安全和更好的提供服务，请绑定您的常用手机号码！
        </div>
        <el-form
          ref="loginFormData"
          :model="loginFormData"
          label-width="22px"
          class="login-form"
        >
          <el-form-item>
            <el-input
              v-model="loginFormData.mobile"
              placeholder="手机号码"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-input
              v-model="loginFormData.captcha"
              placeholder="图形验证码"
            ></el-input>
            <div class="right-content">
              <img :src="imgCode" @click="tapCutImg" />
            </div>
          </el-form-item>
          <el-form-item>
            <el-input
              v-model="loginFormData.validateCode"
              placeholder="验证码"
            ></el-input>
            <div class="right-content">
              <span @click="getCode(1)" v-if="!isCount">获取验证码</span>
              <span class="down" v-else>{{ count }}秒后重发</span>
            </div>
          </el-form-item>
        </el-form>
      </template>
      <span slot="footer" class="dialog-footer">
        <el-button
          @click="handerEditPhone"
          :class="{ 'three-step-button': active == 2 }"
          >{{ getButtonTxt }}</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="提现"
      :visible.sync="withdrawDV"
      width="500px"
      :before-close="closeWithdrawDV"
      :close-on-click-modal="false"
      class="withdraw-DV"
    >
      <div class="withdraw-content">
        <div class="item">
          <span>余额</span
          ><span>{{ user.coinNum }}(充值) + {{ user.giftCoinNum }}(赠送)</span>
        </div>
        <div class="item">
          <span>可提余额</span><span>{{ user.coinNum }}金币</span>
        </div>
        <div class="item">
          <span>理由</span>
          <el-input
            type="textarea"
            :rows="2"
            placeholder="请输入"
            v-model="textarea"
          >
          </el-input>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeWithdrawDV">取 消</el-button>
        <el-button class="blur" @click="submitWithdraw">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="记录"
      :visible.sync="recordDV"
      width="600px"
      :before-close="closeRecordDV"
      :close-on-click-modal="false"
      class="record-DV"
    >
      <div class="record-content">
        <el-table :data="tableData" border style="width: 100%">
          <el-table-column type="index" label="序号" width="50" align="center">
          </el-table-column>
          <el-table-column
            prop="payDate"
            label="日期"
            width="180"
            align="center"
          >
          </el-table-column>
          <el-table-column prop="a" label="类型" align="center">
            <template slot-scope="scope">
              {{ getOrderType(scope.row.orderType) }}
            </template>
          </el-table-column>
          <el-table-column prop="description" label="变化" align="center">
          </el-table-column>
        </el-table>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="blur" @click="closeRecordDV">关闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { userInfo, sendMobileCode, firstBindMobile, validateSmsCode, bindMobile, queryFreeSearchList,modifyNickname,images,mobileExist } from '@/api/user.js'
import { myMixin } from '@/mixins/mixin.js'
export default {
  name: 'userInfo',
  components: {},
  props: {},
  mixins: [myMixin],
  data() {
    return {
      user: {
        userId: '',
        wechatNickname: '',
        mobile: ''
      },
      cursores:true,
      nickname:'',
      imgCode: '/api/captcha/get?type=bindMobile',
      titleDV: '',
      editPhoneDV: false,
      active: 0,
      // 验证身份
      loginFormData: {
        mobile: '',
        captcha: '',
        validateCode: ''
      },
      // 修改手机号
      editFormData: {
        oldMobile: '',
        newMobile: '',
        captcha: '',
        validateCode: ''
      },
      count: 0,
      isCount: false,
      isDisabled: true,
      codeTimeId: '',
      loading: false,
      withdrawDV: false,
      textarea: '',
      recordDV: false,
      tableData: [],
      total: 0,
      recordParams: {
        pageNo: 1,
        pageSize: 1000,
        beginDate: this.format(new Date()),
        endDate: this.format(new Date(this.getStartDate()))
      },
       parm:{
        file:'',
        type:'avatar'
      },
      binding:true,
    }
  },
  computed: {
    getButtonTxt() {
      if (this.active == 0) {
        if (this.user.mobile) {
          return '下一步'
        } else {
          return '绑定'
        }
      } else if (this.active == 1) {
        return '确认'
      } else {
        return '完成'
      }
    },
    getMobile() {
      return this.user.mobile ? this.user.mobile.substr(0, 3) + '****' + this.user.mobile.substr(7) : ''
    }
  },
  watch: {},
  created() {
    this.userInfo()
  },
  methods: {
     // 获取图片
    handleAvatarSuccess(res) {
      // console.log(file);
      this.imgLoading = false
      if (res.success) {
        // URL.createObjectURL(file.raw)
        this.parm.file=res.data
        // this.images()
      }
    },
        // 验证图片信息
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png'
      const isLt5M = file.size / 1024 / 1024 < 5
      if (!isJPG) this.$message.error('上传头像图片只能是JPG和PNG格式!')
      if (!isLt5M) this.$message.error('上传头像图片大小不能超过 5MB!')
      if (isJPG && isLt5M) {
        this.imgLoading = true
      }
      return isJPG && isLt5M
    },
    //头像
    images(){
      images(this.parm).then(res=>{
        console.log(res);
        this.$message.success('图片上传成功')
      })
    },
    //修改名称
    nicknamoes(){
      let poam={
        nickname:this.nickname
      }
      modifyNickname(poam).then(res=>{
        if (res.success) {
        this.cursores=true
        this.$message({
          message: '修改成功',
          type: 'success'
        });
        }
 
      })
    },
    // 获取用户信息
    userInfo() {
      this.loading = true
      userInfo().then(res => {
        if (res.success) {
          this.user = res.data
          this.loading = false
        }
      })
    },
    // 获取图形验证码
    tapCutImg() {
      this.imgCode = '/api/captcha/get?type=bindMobile' + (+new Date())
    },
    // 获取验证码
    sendMobileCode(val) {
      let mobile = ''
      let captcha = ''
      if (val == 0) {
        // 验证身份
        mobile = this.user.mobile
        captcha = this.loginFormData.captcha
      } else {
        if (this.user.mobile) {
          mobile = this.editFormData.newMobile
          captcha = this.editFormData.captcha
        } else {
          captcha = this.loginFormData.captcha
          mobile = this.loginFormData.mobile
        }
      }
      sendMobileCode({
        mobile,
        type: 'sendMobileCode',
        captcha
      }).then(res => {
        if (!res.success) {
          // this.$message.closeAll()
          // this.$message.error(res.message)
          if (res.errorCode == 'web.args.captcha') {
            clearInterval(this.codeTimeId)
            this.isCount = false
          }
        } else {
          this.$message.success('短信验证码已发送，请注意查收')
        }
        this.tapCutImg()
      })
    },
    // 打开修改或绑定手机号弹窗
    handerEdit(val) {
      this.editPhoneDV = true
      this.titleDV = val === 0 ? '修改手机号' : '绑定手机号'
    },
    // 关闭弹窗
    closeEditPhoneDV() {
      this.editPhoneDV = false
      this.loginFormData = {
        mobile: '',
        captcha: '',
        validateCode: ''
      }
      this.editFormData = {
        oldMobile: '',
        newMobile: '',
        captcha: '',
        validateCode: ''
      }
      let timeId = setTimeout(() => {
        this.active = 0
        clearTimeout(timeId)
      }, 500)
    },
    // 步骤确认和绑定手机号按钮
    handerEditPhone() {
      this.$message.closeAll()
      var reg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
      if (this.active === 0) {
        if (this.user.mobile) {
          // 验证身份
          if (this.loginFormData.captcha.trim().length == 0) {
            this.$message.error('请输入图形验证码');
          } else if (this.loginFormData.validateCode.trim().length == 0) {
            this.$message.error('请输入手机验证码');
          } else {
            this.validateSmsCode()
          }
        } else {
          // 绑定手机号
          if (this.loginFormData.mobile.trim().length == 0) {
            this.$message.error('请输入手机号');
          } else if (!reg.test(this.loginFormData.mobile)) {
            this.$message.error('手机号格式有误');
          } else if (this.loginFormData.captcha.trim().length == 0) {
            this.$message.error('请输入图形验证码');
          } else if (this.loginFormData.validateCode.trim().length == 0) {
            this.$message.error('请输入手机验证码');
          } else {
            this.firstBindMobile()
          }
        }
      } else if (this.active === 1) {
        if (this.editFormData.newMobile.trim().length == 0) {
          this.$message.error('请输入手机号');
        } else if (!reg.test(this.editFormData.newMobile)) {
          this.$message.error('手机号格式有误');
        } else if (this.editFormData.captcha.trim().length == 0) {
          this.$message.error('请输入图形验证码');
        } else if (this.editFormData.validateCode.trim().length == 0) {
          this.$message.error('请输入手机验证码');
        } else {
          this.editFormData.oldMobile = this.user.mobile
          this.bindMobile()
        }
      } else {
        this.getInit()
      }
    },
    // 绑定手机号
    firstBindMobile() {
      firstBindMobile(this.loginFormData).then(res => {
        this.$message.closeAll()
        if (res.success) {
          this.getInit()
          this.$message.success('绑定手机号成功');
        } else {
          this.$message.error(res.message);
        }
      })
    },
    // 获取验证码
    getCode(val) {
      this.$message.closeAll()
      var reg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
      if (val == 1) {
        if (this.user.mobile) {
          // 修改手机号
          if (this.editFormData.newMobile.trim().length == 0) {
            this.$message.error('请输入手机号');
            return
          }
          if (!reg.test(this.editFormData.newMobile)) {
            this.$message.error('手机号格式有误');
            return
          }
          if (this.editFormData.captcha.trim().length == 0) {
            this.$message.error('请输入图形验证码');
            return
          }
        } else {
          // 绑定手机号
          if (this.loginFormData.mobile.trim().length == 0) {
            this.$message.error('请输入手机号');
            return
          }
          if (!reg.test(this.loginFormData.mobile)) {
            this.$message.error('手机号格式有误');
            return
          }
          if (this.loginFormData.captcha.trim().length == 0) {
            this.$message.error('请输入图形验证码');
            return
          }
        }
      } else {
        // 验证身份
        if (this.loginFormData.captcha.trim().length == 0) {
          this.$message.error('请输入图形验证码');
          return
        }
      }
      this.isCount = true
      this.count = 59
      //手机是否被注册
      mobileExist({mobile:this.loginFormData.mobile}).then(res=>{
        if (res.data) {
          this.$message({
          message: '该手机已经绑定用户',
          type: 'warning'
        });
          this.binding=false
        }else{
          this.binding=true
          this.sendMobileCode(val)
        }
      })
      this.codeTimeId = setInterval(() => {
        if (this.count == 0) {
          clearInterval(this.codeTimeId)
          this.isCount = false
        } else {
          this.count--
        }
      }, 1000)
    },
    // 验证身份
    validateSmsCode() {
      this.loginFormData.mobile = this.user.mobile
      validateSmsCode(this.loginFormData).then(res => {
        if (res.success) {
          this.active++
          this.isCount = false
        } else {
          this.$message.closeAll()
          this.$message.error(res.message)
        }
      })
    },
    // 修改手机号
    bindMobile() {
      bindMobile(this.editFormData).then(res => {
        this.$message.closeAll()
        if (res.success) {
          this.$message.success('修改手机号成功')
          this.active++
          this.isCount = false
          this.userInfo()
        } else {
          this.$message.error(res.message)
        }
      })
    },
    // 初始化
    getInit() {
      this.closeEditPhoneDV()
      this.userInfo()
    },
    handerWithdraw() {
      this.withdrawDV = true
    },
    submitWithdraw() {

    },
    closeWithdrawDV() {
      this.withdrawDV = false
    },
    handerRecord() {
      this.recordDV = true
      this.queryFreeSearchList()
    },
    queryFreeSearchList() {
      queryFreeSearchList(this.recordParams).then(res => {
        if (res.success) {
          this.tableData = res.data
          this.total = res.data.length
        }
      })
    },
    closeRecordDV() {
      this.recordDV = false
    },
    openActivity() {
      // this.$eventBus.$emit('openActivity')
      this.$router.push('/invite-activity')
    },
    getOrderType(val) {
      const obj = {
        'LINK': '购买链接',
        'SEARCH': '关键词查询',
        'WITHDRAW': '金币提现',
        'REGISTER': '注册奖励',
        'PROMOTION': '推广邀请',
        'GOLD': '金币充值',
        'INVITATION': '商家邀请赠送'
      }
      return obj[val]
    }
  }
}
</script>

<style scoped lang="scss">
.user-info-page {
  width: 1008px;
  height: 100%;
  padding: 20px;
  overflow-y: auto;
  box-shadow: 0px 2px 12px 0px #edecf6;
  border-radius: 4px;
  .user-info {
    height: 100%;
    padding: 30px;
    border: 1px solid #eceff5;
  }
  .message-list {
    margin-bottom: 16px;
    &:nth-child(1) {
      height: 40px;
      span:nth-child(2) {
        width: 180px;
        span {
          width: 200px;
        }
      }
    }
    span:nth-child(1) {
      display: inline-block;
      width: 120px;
    }
    span:nth-child(2) {
      display: inline-block;
      width: 180px;
    }
    span:nth-child(3) {
      width: 185px;
    }

    .cursor-style {
      cursor: pointer;
      color: #0668f6;
    }
    .red {
      color: #ff455b;
    }
    .searchFreeNum {
      color: #fdb944;
    }
  }
  .Changes{
    display: flex;
    margin: 0 auto;
    margin-bottom: 10px;
    justify-content:left;
    align-items: center;
     .cursor-style {
      cursor: pointer;
      color: #0668f6;
    }
    .portrait{
      float: left;
      margin-right: 78px;
      img{
        width: 40px;
        height: 40px;
        border-radius: 100%;
      }
    }
    .texts{
      width: 160px;
      margin-right: 25px;
    }
    .alteration{
      margin-left: 10px;
    }
  }
}
.edit-phone-DV {
  ::v-deep.el-dialog__body {
    padding: 30px 0 !important;
    position: relative;
  }
  .tip {
    position: absolute;
    top: 0px;
    left: 20px;
    font-size: 14px;
    color: #ffa200 !important;
  }
  ::v-deep.el-steps {
    width: 500px;
    .el-step {
      margin-top: -15px;
      margin-left: 30px;
      .el-step__head {
        width: 80px;
      }
      .el-step__head.is-success {
        color: #0668f6;
        border-color: #0668f6;
      }
      .el-step__line {
        height: 1px;
        left: 80%;
        right: -125%;
        background-color: #0668f6;
      }
      .el-step__icon {
        border: 1px solid #0668f6;
      }
      .el-step__icon-inner {
        color: #0668f6;
        font-size: 12px;
        font-weight: normal;
      }
      .el-step__title {
        color: #7f8696;
      }
      .is-process {
        .el-step__icon {
          background-color: #0668f6;
          border-color: #0668f6;
        }
        .el-step__icon-inner {
          color: #fff;
        }
      }
      .el-step__main {
        width: 80px;
        .el-step__title.is-process {
          color: #333;
          font-weight: 0 !important;
        }
      }
    }
  }
  .phone {
    margin: 30px 0 35px 35px;
  }
  ::v-deep.login-form {
    .el-input__inner {
      width: 400px;
      border: 0;
      border-bottom: 1px solid #edeef3;
    }
    .el-form-item__content {
      position: relative;
    }
    .right-content {
      position: absolute;
      right: 38px;
      top: 0;
      width: 80px;
      height: 32px;
      text-align: center;
      line-height: 32px;
      color: #0668f6;
      .down {
        color: #7a7a7a;
      }
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .dialog-footer {
    display: flex;
    justify-content: center;
    margin-top: -20px;
    margin-bottom: 20px;
  }
  .tow-step {
    margin-top: 50px;
  }
  .three-step {
    display: flex;
    flex-direction: column;
    align-items: center;
    i {
      font-size: 42px;
      color: #1bf7a2;
    }
    .edit-success {
      font-size: 20px;
      color: #373e4f;
      margin: 18px 0 28px;
    }
    .my-phone {
      font-size: 16px;
      color: #7f8696;
      margin-bottom: 80px;
    }
  }
  .el-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 400px;
    height: 48px;
    color: #fff;
    font-size: 16px;
    background: linear-gradient(0deg, #0831ff, #3777ff);
  }
  .three-step-button {
    background: linear-gradient(0deg, #0831ff, #3777ff);
  }
}
.withdraw-DV,
.record-DV {
  ::v-deep.el-dialog__body {
    padding: 30px;
  }
  ::v-deep.el-dialog__footer {
    padding-left: 0;
    padding-right: 0;
  }
  .withdraw-content {
    .item {
      span:nth-child(1) {
        display: inline-block;
        width: 80px;
        height: 40px;
      }
    }
    .item:nth-child(3) {
      display: flex;
      ::v-deep.el-textarea__inner {
        width: 350px;
        margin-left: 15px;
      }
    }
  }
  .record-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 180px;
    ::v-deep.has-gutter {
      color: #373e4f;
    }
  }
  .dialog-footer {
    display: flex;
    justify-content: center;
    .el-button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 400px;
      height: 46px;
      border-radius: 4px;
    }
    .blur {
      background: linear-gradient(0deg, #0831ff, #3777ff);
      margin-left: 25px;
      color: #fff;
    }
  }
}
</style>